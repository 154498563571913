<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="client-modal">
    <b-modal no-fade v-model="showDialog" size="xl" :ref="modalId" :id="modalId" :hide-footer="true"
             no-close-on-backdrop>

      <!--Panel content-->
      <div class="client-panel" style="height: auto">
        <div v-if="loaded == true">
          <b-tabs class="client-panel-tabs tabs-title" nav-class="d-inline-flex" v-model="tabIndex">
            <!-- Client details-->
            <b-tab @click="onTabChange(1)" :title="msg('Profile')">
              <div style="min-height: 700px" class="client-panel-content md-scrollbar">
                <clientDetails v-model="client"
                               v-bind:appointments="notExpiredAppointment"
                               @calendarUpdated="refreshAppointments"/>
              </div>
            </b-tab>
            <!-- Org Client details-->
            <b-tab @click="onTabChange(2)" :title="msg('Administrative')" class="clientOrg">
              <div style="padding: 20px 20px 0;">
                <clientDetails v-model="client" :showClient="false" :showOrganization="true"/>
              </div>
              <div style="padding: 0 20px 20px;" v-if="!isNewClient  &&  canOperateRep">
                <representatives v-bind:clientId="client.id"
                                 v-bind:representatives="client.client2Users"
                                 :historyMaxHeight="280"/>
              </div>
            </b-tab>
            <!-- Notes -->
            <b-tab @click="onTabChange(3)" v-if="!isNewClient && canReadNotes">
              <template slot="title">
                <b-spinner type="border" small variant="primary" v-if="tabsUpdating.notes === true"/>
                <span :class="{'mx-1':tabsUpdating.calls === true}">{{ msg('Notes') }}</span>
              </template>
              <div style="padding: 20px;min-height: 700px">
                <notes v-bind:clientId="client.id"
                       :client-name="client.firstname+' '+client.lastname"
                       @update-start="tabsUpdating.notes = true"
                       @update-finish="tabsUpdating.notes = false"></notes>
              </div>
            </b-tab>
            <!-- Phone call -->
            <!--            <b-tab @click="onTabChange(4)" v-if="!isNewClient && canReadPhone && hasPhone" title-item-class="d-none d-lg-inline-block">
                          <template slot="title">
                            <b-spinner type="border" small variant="primary" v-if="tabsUpdating.calls == true"/>
                            <span :class="{'mx-1':tabsUpdating.calls === true}">{{ msg('Calls') }}</span>
                          </template>
                          <perfect-scrollbar :options="{suppressScrollX:true}" style="min-height: 700px">
                            <phoneRecords :phone="client.phone" @update-start="tabsUpdating.calls = true"
                                          @update-finish="tabsUpdating.calls = false"/>
                          </perfect-scrollbar>
                        </b-tab>-->
            <!-- Monetary and bank           -->
            <b-tab @click="onTabChange(4)" :title="msg('Bank')" v-if="!isNewClient && client.clientExt.owner"
                   title-item-class="d-none d-lg-inline-block">
              <div style="min-height: 700px" class="client-panel-content md-scrollbar">
                <clientDetails v-model="client" :showClient="false" :show-bank="true"/>
              </div>
            </b-tab>

            <!-- Document -->
            <b-tab @click="onTabChange(5)" v-if="!isNewClient && canReadDocuments"
                   title-item-class="d-none d-lg-inline-block">
              <template slot="title">
                <b-spinner type="border" class="m-1" small
                           v-if="tabsUpdating.document == true"></b-spinner>
                {{ msg('Documents') }}
              </template>
              <perfect-scrollbar style="" :options="{suppressScrollX:true}">
                <div style="padding: 20px;height: 700px" class="md-scrollbar">
                  <client-documents v-bind:clientId="client.id"
                                    @update-start="tabsUpdating.document = true"
                                    @update-finish="tabsUpdating.document = false"></client-documents>
                </div>
              </perfect-scrollbar>
            </b-tab>

            <b-tab @click="onTabChange(6)" :title="msg('Bookings')" v-if="!isNewClient"
                   title-item-class="d-none d-lg-inline-block">
              <div style="min-height: 700px" class="client-panel-content md-scrollbar">
                <clientDetails v-model="client" :showClient="false" :show-bookings="true" :bookings="bookings"/>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div v-if="loaded != true && this.error != null">
          <b-tabs class="tabs-title">
            <b-tab :title="msg('Profile')">
              <div style="padding: 20px">
                <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i><strong>{{
                    error
                  }}</strong></b-alert>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else-if="loaded != true">
          <b-tabs class="tabs-title">
            <b-tab :title="msg('Profile')">
              <div style="text-align: center;height: 100%;padding: 30% 20px 20px;position: relative"
                   class="md-scrollbar">
                <div class="position-absolute w-100 text-center text-primary" style="top:50%">
                  <b-spinner label="Spinning" variant="primary"/>
                  {{ msg('loading') }}
                </div>

              </div>
            </b-tab>
          </b-tabs>

        </div>
      </div>
      <!--Panel footer-->
      <div class="text-center" style="justify-content:center">
        <b-button style="min-width: 217px" variant="outline-primary" class="btn-dialog" @click="submit" v-if="canSave"
                  :disabled="saveIsDisabled">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>

  </div>

</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import notes from '../panels/notes/notes'
import phoneRecords from '../panels/client/phoneRecords'
import representatives from '../panels/client/representatives'
import clientDetails from '../panels/client/clientDetails'
import {date, validation} from '../../global/helper'
import ClientDocuments from '../panels/client/clientDocuments'

export default {
  name: 'clientModal',
  components: {
    ClientDocuments,
    notes,
    // eslint-disable-next-line vue/no-unused-components
    phoneRecords,
    representatives,
    clientDetails,

  },
  data() {
    return {
      uniqueId: 0,
      loaded: false,
      error: null,
      tabsUpdating: {
        notes: false,
        calls: false,
        treatments: false,
        objectives: false,
        progress: false,
        document: false,
      },
      tabIndex: 0,

      saveButton: 0,
      disabledSaveButton: {},

      clientCopy: {},
      appointments: [],
      timerActive: false,
      bookings: [],
      //Listeners
      listeners: {
        refresh: () => {
        },

      }
    }
  },
  props: {
    id: Number,
    dialog: {
      required: true,
      default: false,
      type: Boolean
    },
    canCreate: {
      required: false,
      default: false,
      type: Boolean
    },

  },
  watch: {
    id: function () { // watch it
      this.error = null
      this.tabIndex = 0
      this.disabledSaveButton = {}
      this.saveButton = 0
      this.onLoad()
    },
    dialog() {
      let $this = this

    }
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('clients', ['canOperateRep']),
    ...mapGetters('phone', ['canReadPhone']),
    ...mapGetters('notes', ['canReadNotes']),
    ...mapGetters('document', ['canReadDocuments']),
    ...mapGetters('data', ['canReadUsers', 'defaultCountry', 'defaultLang']),
    ...mapGetters('data', {
      _getAffiliateIdByCampaignId: 'getAffiliateIdByCampaignId',
      _getAffiliateIdByBannerId: 'getAffiliateIdByBannerId',
      _getCampaignByAffId: 'getCampaignByAffId',
      _getBanners: 'getBanners'
    }),
    ...mapState({
      data: function (store) {
        return store.data
      }
    }),
    client: {
      get() {
        return this.clientCopy
      },
      set(v) {
        if (v == null) {
          this.clientCopy = {
            id: 0,
            creationTime: this.$moment(new Date())
                .format('YYYY-MM-DD HH:mm'),
            lang: this.defaultLang,
            branchId: 0,
            clientExt: {},
            country: this.defaultCountry,
            representativeId: this.data.userId,
          }
          return
        }
        return this.clientCopy = v
      }
    },
    showDialog: {
      get() {
        return this.dialog == true
      },
      set(val) {
        this.$emit('update:dialog', (val == true))
      }
    },
    notExpiredAppointment() {

      if (this.appointments == null) {
        return []
      }

      if (this.isNewClient) {
        return []
      }

      return this.appointments.filter(p => Date.parse(p.start) > Date.now())

    },
    modalId() {
      return 'client___modal___' + this.uniqueId
    },
    isNewClient() {
      return this.id == null || isNaN(this.id) || this.id <= 0
    },
    hasAccount() {
      return this.id != null && this.id > 0 && this.loaded === true && this.client.account != null
    },
    hasPhone() {
      return this.id != null && this.id > 0 && this.loaded === true && this.client.phone != null
    },
    canSave() {
      return this.saveButton != null
    },
    saveIsDisabled() {
      return this.disabledSaveButton[this.saveButton] != null && this.disabledSaveButton[this.saveButton] !== true
    }

  },
  created() {
    this.uniqueId = this._uid
    this.onLoad()

    this.$root.$on('crm::calendar::refresh', (this.listeners.refresh = () => {
      this.loadClient()
    }))

  },
  beforeDestroy() {
    this.$root.$off('crm::calendar::refresh', this.listeners.refresh)

  },
  methods: {
    ...mapActions('clients', {
      _getClient: 'get',
      _updateClient: 'update',
      _register: 'register',
    }),
    ...mapActions('calendar', ['clientAppointments']),
    ...mapActions('realEstateContracts', ['getById', 'getAllByClients']),

    onLoad() {

      console.log('Client_modal -> onLoad,', {
        canCreate: this.canCreate === true,
        id: this.id
      })
      this.loaded = false
      this.client = null
      if (this.id == null) {
        this.loaded = true
        return

      }

      if (typeof this.id === 'number' && this.id > 0) {
        this.loadClient()

      } else {
        this.loaded = true
      }
    },
    dateStr(d) {
      return date.dateStringFormated(d, true)
    },
    loadClient() {
      let $this = this
      $this.loaded = false

      // Check if id is valid
      if (isNaN(this.id) || this.id <= 0) {
        this.error = this.msg('Invalid id ') + this.id
        return
      }

      // Fetch client and appointments
      this._getClient({ id: $this.id })
          .then(({ client, appointments }) => {
            // Refresh appointments
            $this.refreshAppointments()

            // After getting client, get affiliate details
            if (client.bannerid > 0 && (client.camId <= 0 || client.affId <= 0)) {
              let aff = this._getAffiliateIdByBannerId(client.bannerid)
              // Generate a new object with affiliate details
              $this.client = {
                ...client,
                affId: aff.affiliateId,
                camId: aff.campaignId
              }
            } else if (client.camId > 0 && client.affId <= 0) {
              let affId = this._getAffiliateIdByCampaignId(client.camId)
              $this.client = {
                ...client,
                affId
              }
            } else {
              $this.client = { ...client }
            }

            // Set page as loaded
            $this.loaded = true

            // Save appointments
            $this.appointments = appointments

            // Set default country and language if needed
            if ($this.client.country == null || $this.client.country <= 0) {
              $this.client.country = this.defaultCountry
            }
            if ($this.client.lang == null || $this.client.lang <= 0) {
              $this.client.lang = this.defaultLang
            }

            // Fetch all bookings after appointments are fetched
            return this.getAllByClients($this.id)
          })
          .then((ans) => {
            // Save bookings in this.bookings
            $this.bookings = ans["Contracts"]
          })
          .catch((error) => {
            // Handle errors
            $this.error = error
          })
    }
,
    refreshAppointments() {
      let $this = this
      this.clientAppointments({clientId: $this.id})
          .then(app => {
            if (app != null) {
              this.appointments = app
            }
          })
    },
    saveEvent() {
      this.$root.$emit('crm::submitCalendar')
    },
    onCalendarSubmitted(ans) {
      if (ans == null || ans.status === true) {
        this.dismissPopover()
      }
    },
    dismissPopover() {
      this.$root.$emit('bv::hide::popover')
    },
    onTabChange(tabIndex) {

      this.saveButton = tabIndex
      switch (tabIndex) {
        case 3:
          this.$root.$emit('crm::client::note::load')
          this.saveButton = null
          break
        case 5:
          this.$root.$emit('crm::client::documents::load')
          this.saveButton = null
          break
      }
    },
    submit(e) {
      console.log('submit1')
      if (this.saveButton <= 2 || this.saveButton === 4) { // profile update
        if (typeof e === 'object' && typeof e.preventDefault === 'function') {
          e.preventDefault()
        }

        console.log('submit2')

        //TODO: CHECK IT
        /*if (this.$v.$invalid === true)
            return;*/
        console.log('submit3')

        if (!isNaN(this.client.id) && this.client.id > 0) {
          return this.updateClient()
        }
        return this.createClient()

      }

    },
    createClient() {
      console.log('updateClient()')
      let $this = this
      this._register({client: this.client})
          .then(
              (a) => {
                $this.$emit('newClient', a)
              },
              () => $this.$emit('newClient', 0)
          )
    },
    updateClient() {
      let $this = this
      this._updateClient({
        id: this.client.id,
        client: this.client
      })
          .then(
              () => {
                $this.$root.$emit('crm::client-table::refresh')
                $this.$emit('clientUpdated', true)

              },
              () => $this.$emit('clientUpdated', false)
          )
    },

    closeDialog() {
      this.$emit('closeDialog')
    },

  },
}
</script>
<style>
</style>
